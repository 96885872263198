'use client';

import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronRight } from 'react-icons/hi';

import { getAmplitudeDeviceId } from '@/src/utils';
import { addParameter } from '@/src/utils/addParameter';
import { dashboardUrl } from '@/src/utils/processenv';

export const StartTrialInput = () => {
  const [signupUrl, setSignupUrl] = useState(`${dashboardUrl}/signup`);
  const { t } = useTranslation();

  // add query params - amplitude - to URL without removing its existing params
  function addAmplitudeId(amplitudeId: string) {
    setSignupUrl(
      addParameter(signupUrl, 'amplitudeDeviceId', amplitudeId).toString()
    );
  }

  useEffect(() => {
    const aId = getAmplitudeDeviceId();
    if (aId) {
      addAmplitudeId(aId);
    } else {
      setTimeout(() => {
        addAmplitudeId(getAmplitudeDeviceId());
      }, 1000);
    }
  }, []);

  return (
    <>
      <div className="flex flex-col items-center gap-3 md:flex-row">
        <a
          href={signupUrl}
          className="w-52 rounded-lg border-2 border-[#318AE5] bg-[#318AE5]  p-3 text-start font-medium text-white md:w-56 md:px-6"
        >
          <p className="text-xs md:text-sm">{t('Teachers/Therapists')}</p>
          <div className="flex items-center gap-1">
            <p className="md:text-lg">{t('Sign Up for Free')}</p>
            <HiChevronRight size={24} />
          </div>
        </a>
        <Link href="/schools-and-districts">
          <button className="w-52 rounded-lg border-2 border-[#318AE5] bg-white  p-3 text-start font-medium text-[#318AE5] md:w-56 md:px-6">
            <p className="text-xs md:text-sm">{t('Administrators')}</p>
            <div className="flex items-center gap-1">
              <p className="md:text-lg">{t('Learn more')}</p>
              <HiChevronRight size={24} />
            </div>
          </button>
        </Link>
      </div>
    </>
  );
};
