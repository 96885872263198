'use client';

import Image from 'next/image';
import { useRouter } from 'next/navigation';
import check from 'public/images/icons/Check.svg';
import translate from 'public/images/icons/Translate.svg';
import { useEffect, useState } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';

import i18n from '@/src/i18n';
import { getLanguageCode } from '@/src/utils/lang';

interface IProps {
  isHeader: boolean;
}
export const ChooseLang = ({ isHeader = false }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState('English');
  const [isMounted, setIsMounted] = useState(false);
  const router = useRouter();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  let hoverTimeout: NodeJS.Timeout;

  const handleMouseEnter = () => {
    hoverTimeout = setTimeout(() => {
      setIsTooltipVisible(true);
    }, 2000); // Delay of 2 seconds
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setIsTooltipVisible(false);
  };

  const handleLanguageChange = (language: string) => {
    const val = language === 'Español' ? 'es' : 'en';
    localStorage.setItem('lang', val);
    i18n.changeLanguage(val);
    setSelectedLang(language);
    setIsOpen(false);
    router.refresh();
  };

  const languages = ['English', 'Español'];

  // Load language from localStorage when component mounts
  const lang = getLanguageCode(
    typeof window !== 'undefined' ? localStorage.getItem('lang') : 'en'
  );

  useEffect(() => {
    i18n.changeLanguage(lang);
    setSelectedLang(lang === 'es' ? 'Español' : 'English');
    setIsMounted(true);
  }, [lang]);

  return (
    <div className="relative inline-block text-left">
      {/* Button Section */}
      <div
        className="group relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <button
          onClick={toggleDropdown}
          className={`${
            isOpen ? 'bg-[#F6F7F8]' : 'bg-transparent hover:bg-[#F6F7F8]'
          } mt-0.5 flex items-center gap-2 rounded-md px-4 py-2 focus:outline-none`}
        >
          <Image
            src={translate}
            alt="choose language"
            // className="h-6 w-6 rounded-full"
            width="24"
            height="24"
          />
          {isMounted && (
            <span className="font-normal text-[#464E5F]">{selectedLang}</span>
          )}
          <span className="ml-1">
            <IoChevronDownOutline color="#6B717F" />
          </span>
        </button>
        {!isOpen && !isHeader && isTooltipVisible && (
          <div className="absolute left-1/2 z-50  mt-3 hidden w-max  -translate-x-1/2 items-center rounded-md bg-[#464E5F] px-3 py-2 text-sm text-white shadow-lg group-hover:flex">
            Change language of this page
            {/* Centered Arrow */}
            <div className="absolute -top-1 left-1/2 h-2 w-2 -translate-x-1/2 rotate-45 bg-[#464E5F]"></div>
          </div>
        )}
      </div>
      {/* Dropdown Section */}
      {isOpen && isMounted && (
        <div className="absolute left-1/2 z-10 mt-2 w-40 -translate-x-1/2 rounded border border-gray-300 bg-white p-1 shadow-lg">
          {languages.map((language, index, arr) => (
            <div
              key={language}
              onClick={() => handleLanguageChange(language)}
              className={`flex w-full cursor-pointer items-center justify-between rounded-md p-3 font-normal ${
                index + 1 !== arr.length ? 'mb-1' : ''
              }  ${
                selectedLang === language
                  ? ' bg-[#F0F7FF] text-blue-primary'
                  : 'text-[#6B717F] hover:bg-[#F6F7F8]'
              }`}
            >
              {language}
              {selectedLang === language && (
                <Image
                  src={check}
                  alt="choose language"
                  // className="rounded-full"
                  width="14"
                  height="14"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChooseLang;
