'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useEffect } from 'react';

import i18n from '@/src/i18n';
import { getLanguageCode } from '@/src/utils/lang';

export const Footer = () => {
  // Load language from localStorage when component mounts
  const lang = getLanguageCode(
    typeof window !== 'undefined' ? localStorage.getItem('lang') : 'en'
  );

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  return (
    <div className="bg-[#283859]">
      <div className="container mx-auto p-5 md:py-10 lg:px-4">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between ">
          <div className="flex flex-col md:w-full lg:ml-14 lg:w-[85%] ">
            <div className="flex items-center gap-x-4 font-medium tracking-wide text-gray-100 md:justify-center lg:justify-start">
              <Link href="/">
                <span>
                  <Image
                    src="/images/logo-blue.svg"
                    alt="AbleSpace Logo"
                    className="h-7 w-7"
                    width={28}
                    height={28}
                  />
                </span>
              </Link>
              <span className="py-3">ABLESPACE</span>
            </div>

            {/* feature and more links */}
            <div className="mt-5 flex h-64 flex-col flex-wrap gap-x-24 gap-y-5 text-white md:h-full md:w-full md:flex-row md:justify-center md:gap-x-8 lg:flex-row lg:justify-start xl:w-[85%]">
              <Link href="/features">
                <span className="text-base">{i18n.t('Features')}</span>
              </Link>
              <Link href="/tutorials">
                <span className="text-base">{i18n.t('Tutorials')}</span>
              </Link>
              <Link href="/blog">
                <span className="text-base">Blog</span>
              </Link>
              <Link href="/hipaa">
                <span className="text-base">HIPAA</span>
              </Link>
              <Link href="/ferpa">
                <span className="text-base">FERPA</span>
              </Link>
              <Link href="/reviews">
                <span className="text-base">{i18n.t('Reviews')}</span>
              </Link>
              <Link href="/faqs">
                <span className="text-base">{i18n.t('FAQ')}</span>
              </Link>
              <Link href="/schools-and-districts">
                <span className="text-base">{i18n.t('Schools/Districts')}</span>
              </Link>
              <Link href="/enterprise">
                <span className="text-base text-white">
                  {i18n.t('Enterprises')}
                </span>
              </Link>
              <Link href="/privacy-policy">
                <span className="text-base text-white">
                  {i18n.t('Privacy Policy')}
                </span>
              </Link>
              <Link href="/terms-of-service">
                <span className="text-base text-white">
                  {i18n.t('Terms of Service')}
                </span>
              </Link>
            </div>
          </div>

          {/* App Redirect Links */}

          <div className="mt-5 flex items-center justify-center space-x-2 md:gap-4 md:space-x-3 ">
            <Link
              href="https://play.google.com/store/apps/details?id=io.ablespace.androidapp"
              className="h-[40px] w-auto md:h-[58px] md:w-[195px]"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={`/images/customers/${
                  lang === 'es' ? 'playstore_es.svg' : 'playstore.svg'
                }`}
                alt={`AbleSpace Android ${i18n.t(
                  'App for Tracking IEP Goals, Data Collection and Student Assessment Reports'
                )}`}
                width={195}
                height={58}
                className="h-full w-full object-contain"
              />
            </Link>

            <Link
              href="https://apps.apple.com/us/app/ablespace-iep-goal-tracking/id1662372988"
              className="h-[40px] w-auto md:h-[58px] md:w-[173px]"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={`/images/customers/${
                  lang === 'es' ? 'ios_es.svg' : 'ios.svg'
                }`}
                alt={`AbleSpace iOS ${i18n.t(
                  'App for Tracking IEP Goals, Data Collection and Student Assessment Reports'
                )}`}
                width={173}
                height={58}
                className="h-full w-full object-contain"
              />
            </Link>
          </div>
        </div>
      </div>

      {/* divider */}
      <div className="mx-auto mt-1 w-[90%] border border-[#3F598C]"></div>

      {/* social icons */}
      <div className="container mx-auto mt-4 w-[84%] pb-10">
        <div className="flex flex-col gap-4 md:w-full md:flex-row md:justify-between md:gap-0 md:p-0 md:py-5">
          <div className="flex items-center justify-center gap-x-4 md:order-2 md:self-end ">
            <a
              href="https://www.facebook.com/ablespace.io/"
              className="h-[28px] w-[28px]"
            >
              {/* <OptimisedImage
                defaultImagePath="/images/icons/social-media/facebook/Facebook@3x.png"
                data={[
                  {
                    imagePath:
                      '/images/icons/social-media/facebook/Facebook@3x.png',
                    imageWidth: 0,
                    alt: 'Facebook',
                  },
                ]}
              /> */}
              <Image
                src="/images/icons/social-media/facebook/Facebook@3x.png"
                width={28}
                height={28}
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/ablespace.io/"
              className="h-[28px] w-[28px]"
            >
              {/* <OptimisedImage
                defaultImagePath="/images/icons/social-media/instagram/Instagram@3x.png"
                data={[
                  {
                    imagePath:
                      '/images/icons/social-media/instagram/Instagram@3x.png',
                    imageWidth: 0,
                    alt: 'Instagram',
                  },
                ]}
              /> */}
              <Image
                src="/images/icons/social-media/instagram/Instagram@3x.png"
                width={28}
                height={28}
                alt="Instagram"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UC3g6DELgtautJeke1tHVf6g/"
              className="h-[28px] w-[28px]"
            >
              {/* <OptimisedImage
                defaultImagePath="/images/icons/social-media/youtube/YouTube@3x.png"
                data={[
                  {
                    imagePath:
                      '/images/icons/social-media/youtube/YouTube@3x.png',
                    imageWidth: 0,
                    alt: 'Youtube',
                  },
                ]}
              /> */}
              <Image
                src="/images/icons/social-media/youtube/YouTube@3x.png"
                width={28}
                height={28}
                alt="Youtube"
              />
            </a>
          </div>
          <div className="text-center text-sm text-gray-200 md:order-1">
            © 2025 AbleSpace. {i18n.t('All rights reserved')}.
          </div>
        </div>
      </div>
    </div>
  );
};
